import app from './base'
import $t from './translation'
import popup from './popup'
import validator from "./validator";
import {serializeObject} from "./helper"

app.event.bind('form.page', function(params) {
    const $form = jQuery(params.form);
    jQuery('input[name="page"]').val(params.page);
    $form.submit();
});

app.event.bind('form.submit.captcha', function(params) {
    grecaptcha.ready(function() {
        grecaptcha.execute('6Lcwd18UAAAAAKwns2MU4ZkO6igjLhaFdnyYpyqt', {action: 'submit'}).then(function(token) {
            app.event.trigger('form.submit', params);
        });
    });
});

app.event.bind('form.submit', function(params) {
    const $form = jQuery(params["form"] || this);

    if ($form.hasClass('no-ajax')) {
        $form.unbind("submit.validator").submit();
        return;
    }

    const action = $form.attr('action');
    const method = $form.attr('method');
    const responsePopup = params["response-popup"] || false;
    const responseContainer = params["response-container"] || false;
    const responseReplace = params["response-replace"] || false;

    const baseData = {layout: "ajax"};
    const formData = serializeObject($form);
    const additionalData = jQuery(this).data('params-data') || {};

    popup.close();
    popup.loading($t('lbl.submitting'));

    jQuery.ajax({
        type: method,
        url: action,
        data: jQuery.param(jQuery.extend({}, baseData, formData, additionalData)),
        error: function(XMLHttpRequest, textStatus, errorThrown) {
            popup.close();

            console.error('form.submit error', textStatus);
        },
        success: function(response, textStatus) {
            popup.close();

            if (response.redirect) {
                window.location = response.redirect;
            }

            if(responseContainer === 'reload'){
                window.location.reload();
            } else if(responseContainer !== null && responseContainer !== false) {
                if (responseReplace) {
                    $(responseContainer).replaceWith(response);
                } else {
                    $(responseContainer).html(response);
                }
                app.event.trigger('document.changed');
            } else if(responsePopup !== null && responsePopup !== false) {
                popup.html(response);
            } else if(response.length > 0) {
                const $response = $(response);
                const $message = $response.find('#message-list');
                const $errors = $response.find('#error-list');

                if ($errors.length > 0) {
                    popup.html($errors);
                }
                if ($message.length > 0) {
                    popup.html($message);
                }
            }

            $form.trigger("form.submit.done");

            //
            // if(typeof(params.show_loading) == 'undefined' || params.show_loading != false){
            //     cmsClient.hideLoading();
            // }
            //
            // // Have we got an error?
            // if(response.length > 0 && $(response).find('#error-list').length > 0 && ignoreErrors != false)
            // {
            //     cmsClient.showError($(response).find('#error-list').html());
            // } else {
            //     // cmsAdministration.removeTinyMCE(); /// remove old editor if exists --- !!! removed because after posting ajax form, all tinyMCE were removed
            //     // if we have a handler send the response there and leave the  message output to the handler
            //     if(successHandler != '' && typeof(successHandler) != 'undefined')
            //     {
            //         successHandler(response, textStatus);
            //     } else {
            //         if(response.length > 0 && $(response).find('#message-list').length > 0) {
            //             cmsClient.showMessage($(response).find('#message-list').html());
            //         }
            //     }
            // }
        }
    });
});

app.event.bind('form.validate', function() {
    validator.init(jQuery(this));
});

app.event.bind('form.textarea.size', function(params) {
    const $field = jQuery(this);

    if ($field.val().length > params.maxlimit) {
        $field.val( $field.val().substring(0, params.maxlimit) );
    } else {
        jQuery(params.countfield).html( (params.maxlimit - $field.val().length) );
    }
});

app.event.bind('form.hidden.show', function(params) {
    const $form = jQuery(params.target);
    $form.addClass('is-visible');
});
