import app from './base'
import slick from "slick-carousel";

app.event.bind('slider.init', function() {
    jQuery(this).slick()
        // .on('lazyLoaded', function (e, slick, image, imageSource) {
        //
        //     image.attr('src',''); //remove source
        //     image.css('background-image','url("'+imageSource+'")'); //replace with background instead
        //     image.css('background-size','cover');
        //     image.css('background-position','center');
        //     image.css('background-repeat','no-repeat');
        //
        // });
});
