import { APP_NAME } from "./constants";

const configurationQueue = window[APP_NAME].q.slice();

let configuration = {
    debug: false,
};

for (let i = 0; i < configurationQueue.length; i++) {
    const queueItem = configurationQueue[i];

    if (queueItem.length && queueItem[0] === 'init') {
        configuration = Object.assign({}, configuration, queueItem[1]);
    }

    if (queueItem.length > 2 && queueItem[0] === 'set' && queueItem[1] === 'config') {
        configuration = Object.assign({}, configuration, queueItem[2]);
    }
}

export default {
    all: function() {
        return configuration;
    }
    , get: function(key, default_value) {
        const data = this.all();
        return (data[key] === undefined ? default_value : data[key]);
    }
}
