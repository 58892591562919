const c = JSON.stringify || function (a) {
    var b = typeof a;
    if (b != "object" || a === null) return b == "string" && (a = '"' + a + '"'), String(a);
    var d, e, f = [], g = a && a.constructor == Array;
    for (d in a) e = a[d], b = typeof e, b == "string" ? e = '"' + e + '"' : b == "object" && e !== null && (e = c(e)), f.push((g ? "" : '"' + d + '":') + String(e));
    return (g ? "[" : "{") + String(f) + (g ? "]" : "}")
};

let storage = {
    cookie: {
        set: function (name, value, options) {
            options || (options = {
                path: "/",
            }), value === null && (options.expires = -1);
            if (typeof options.expires == "number") {
                var d = options.expires, e = options.expires = new Date;
                e.setDate(e.getDate() + d)
            }
            return document.cookie = [encodeURIComponent(name), "=", options.raw ? String(value) : encodeURIComponent(String(value)), options.expires ? "; expires=" + options.expires.toUTCString() : "", options.path ? "; path=" + options.path : "", options.domain ? "; domain=" + options.domain : "", options.secure ? "; secure" : ""].join("")
        }, get: function (name, b) {
            b || (b = {});
            var c, d = b.raw ? function (a) {
                return a
            } : decodeURIComponent;
            return (c = RegExp("(?:^|; )" + encodeURIComponent(name) + "=([^;]*)").exec(document.cookie)) ? d(c[1]) : null
        }, setObject: function (a) {
            this.set("cms_b3studio", c(a), {path: "/"})
        }, getObject: function () {
            var a;
            try {
                a = jQuery.parseJSON(this.get("cms_b3studio"))
            } catch (b) {
            }
            return a ? a : {}
        }, setObjectValue: function (a, b) {
            var c = this.getObject();
            c[a] = b, this.setObject(c)
        }, getObjectValue: function (a) {
            var b = this.getObject();
            return b ? b[a] ? b[a] : "" : ""
        }
    }
};

export default storage;