import './polyfill'
import configuration from './configuration';
import {boolval} from './helper'

let data = {};
let debug = configuration.get('debug', false);

function mergeObjects(/* variable number of arrays */) {
    let result = {},
        length = arguments.length,
        object = null;

    if ( length < 2 ) {
        throw "Must merge two or more objects";
    }

    for (let i=0; i < length; ++i) {
        object = arguments[i];
        for (let key in object) {
            if (!object.hasOwnProperty(key)) {
                continue;
            }
            result[key] = object[key];
        }
    }
    return result;
}

function getConstant(name) {
    if(debug) { console.info('constants.getConstant', name); }

    const events = app.data.get('constants');
    if (!events[name]) {
        events[name] = [];
    }
    return events[name];
}

function getDataAttributes(node, prefix) {
    let d = {},
        re_dataAttr = new RegExp('^data\-' + (typeof (prefix) != 'undefined' ? prefix + '-' : '') + '(.+)$');

    jQuery.each(node.get(0).attributes, function(index, attr) {
        if (re_dataAttr.test(attr.nodeName) ) {
            const key = attr.nodeName.match(re_dataAttr)[1];
            d[key] = attr.nodeValue;
        }
    });

    return d;
}

function getEvent(name) {
    if(debug) { console.info('event.getEvent', name); }

    const events = app.data.get('events');
    if (!events[name]) {
        events[name] = [];
    }

    if(debug) { console.info('event.getEvent.result', arguments, events[name]); }

    return events[name];
}

function handleBoundEvents(event) {
    const $this = $(this);
    const disabled = $this.attr('disabled');
    if(disabled === 'disabled') {
        event.preventDefault();
        return;
    }
    const action = $this.attr('data-event');
    if(action === 'ready') {
        if( typeof $this.attr('data-event-single') !== 'undefined' ) $this.removeClass('event');
        if( typeof $this.attr('data-event-multiple') === 'undefined' ) $this.removeClass('event');
    }
    const event_default = boolval($this.attr('data-event-default'));
    if(event !== undefined && typeof(event) == 'object' && event_default === false ) {
        event.preventDefault();
    }
    const event_name = $this.attr('data-event-trigger');
    const event_params = getDataAttributes($this, 'params');
    if(event_name !== undefined && event_name.length > 0) app.event.trigger(event_name, this, jQuery.extend({ event: event }, event_params) );
    const form_name = $this.attr('data-event-form');
    if(form_name !== undefined && form_name.length > 0) jQuery(form_name).submit();
    return !(event_default === false);
}

let app = {
    initialize: function() {
        if(debug) { console.info('initialize', this.config.all()); }
    }
    , config: configuration
    , data: {
        get: function(key) {
            if (!data[key]) {
                data[key] = [];
            }
            return data[key];
        },
        set: function(key, value) {
            let d = this.get(key);
            d.push(value);
            return this;
        },
        print: function() {
            console.log(data);
        }
    }
    , constants: {
        get: function(name) {
            name = name.toUpperCase();
            const val = getConstant(name);
            if (!val || (val.length === 0)) {
                throw("Constants doesn`t exist");
            }
            return val[0];
        },
        set: function(name, value) {
            name = name.toUpperCase();
            let val = getConstant(name);
            if (!val || (val.length === 0)) {
                val.push(value);
            } else {
                throw("Constants cannot be modified");
            }
        }
    }
    , event:  {
        handle: handleBoundEvents,
        bind: function(name, fn, args, eventHandlerId, single) {
            if(debug) { console.info('event.bind', arguments); }

            // Parse incoming object
            if(typeof(name) == 'object') {
                const params = name;
                name = params.name;
                fn = params.fn;
                args = params.args;
                eventHandlerId = params.eventHandlerId;
                single = params.single;
            }

            const e = getEvent(name);
            if (!eventHandlerId || eventHandlerId === null) {
                //there was no event id, so auto-generate one
                eventHandlerId = name + '-' + (e.length + 1) + (single ? '.single' : '');
            }

            // todo check eventHandlerId must be string!!!

            // Did we swap function and arguments
            if(typeof(fn) != 'function' && typeof(args) == 'function') {
                const temp = args;
                args = fn;
                fn = temp;
            }

            if(!args) {
                args = {};
            }

            //add the id to the event
            fn.id = eventHandlerId;
            fn.args = args;
            e.push(fn);

            return this;
        },
        trigger: function(name, source, args) {
            if(debug) { console.info('event.trigger', arguments); }

            if (!source) {
                source = {};
            }
            if (!args) {
                args = [];
            }
            let evt = getEvent(name);
            if (!evt || (evt.length === 0)) {
                return this;
            }
            evt = evt.length === 1 ? [evt[0]] : Array.apply(null, evt);
            for (let i = 0, l = evt.length; i < l; i++) {
                const handler = evt[i];
                const f_args = [mergeObjects(handler.args, args)];
                if(debug) { console.warn('event.trigger -> ' + handler.id, f_args); }
                handler.apply(source, f_args );

                if(handler.id.indexOf('.single') >= 0) {
                    app.event.unbind(name, handler.id);
                }
            }

            return this;
        },
        unbind: function(name, eventHandlerId) {
            let evt = getEvent(name);
            if (evt && evt.length > 0) {
                //if there is an event handler to remove look for it
                if (eventHandlerId) {
                    for (let i = 0, l = evt.length; i < l; i++) {
                        const e = evt[i];
                        if (e.id === eventHandlerId) {
                            evt.splice(i, 1);
                            break;
                        }
                    }
                } else {
                    //remove all event handlers
                    evt = [];
                }
            }

            return this;
        },
        isRegistered: function(name, eventHandlerId) {
            const evt = getEvent(name);
            if (eventHandlerId) {
                for (let i = 0, l = evt.length; i < l; i++) {
                    const e = evt[i];
                    if (e.id === eventHandlerId) {
                        return true;
                    }
                }
            }
            return false;
        }
    }
};


function tapHandler(event) {
    const $elem = $(this);
    $elem.data(event.type, 1);
    if (event.type === 'touchend' && !$elem.data('touchmove')) {
        app.event.handle.apply(this, arguments);
    } else if ($elem.data('touchend')) {
        $elem.removeData('touchstart touchmove touchend');
    }
}

/* Bind events */
app.event.bind('document.ready', function() {
    jQuery(document).on('keyup', '.event[data-event=keyup]', app.event.handle);
    jQuery(document).on('click', '.event[data-event=click]', app.event.handle);
    jQuery(document).on('touchstart', '.event[data-event=click]', tapHandler);
    jQuery(document).on('touchmove', '.event[data-event=click]', tapHandler);
    jQuery(document).on('touchend', '.event[data-event=click]', tapHandler);
    jQuery(document).on('change', '.event[data-event=change]', app.event.handle);
    jQuery(document).on('submit', '.event[data-event=submit]', app.event.handle);

    // document.addEventListener('touchstart', function (event) {
    //     if (event.target.classList.contains('.event[data-event=click]')) {
    //         // Do something
    //     }
    // })
}, {}, null, true);

app.event.bind('document.changed', function() {
    jQuery('.event[data-event=ready]').each(function() {
        app.event.handle.call(this);
    });
});

/* Bind from HTML, ex: <div ...data-event="ready" data-event-trigger="app.event.bind" data-params-event="upload.finished" data-params-trigger="filemanager.refresh"...> */
app.event.bind('app.event.bind', function(bindParams) {
    const target = this;
    app.event.bind(bindParams.event, function(eventParams) {
        app.event.trigger(bindParams.trigger, target, eventParams);
    });
});

/* Trigger events on ready */
app.event.bind('document.ready', function() {
    jQuery('.event[data-event=ready]').each(function() {
        app.event.handle.call(this);
    });
});

export default app;