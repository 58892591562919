import app from './base'

app.event.bind('document.ready', function() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

        // Add a click event on each of them
        $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = document.getElementById(target);

                // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                el.classList.toggle('is-active');
                $target.classList.toggle('is-active');

            });
        });
    }

    // main menu toggle click handler
    const mainMenu = document.querySelector('.main-menu .menu-list');

    if (mainMenu) {
        const mainMenuToggles = Array.prototype.slice.call(mainMenu.querySelectorAll('.toggle'), 0);

        // Add a click event on each of them
        mainMenuToggles.forEach( el => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                // Get the target from the "data-target" attribute
                const target = el.dataset.target;
                const $target = mainMenu.getElementsByClassName(target);

                if ($target) {
                    $target[0].classList.toggle('is-active');
                }
            });
        });
    }

    $('.navbar-mobile .navbar-menu .arrow').click(function(e) {
        if ($(this).parent().next().length === 0) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        $(this).parents('.navbar-item.has-dropdown:first').toggleClass('is-active');
    });

    // var nav = new Navbar({
    //     element: document.querySelector('.navbar-mobile'),
    //     //sticky: true,
    // });

    // const nav = document.querySelector('.navbar-mobile');
    // const navTop = nav.offsetTop + nav.offsetHeight;
    // window.addEventListener('scroll', function () {
    //     let pageY = window.pageYOffset || document.documentElement.scrollTop;
    //     if (pageY >= navTop) {
    //         document.body.classList.add('has-navbar-fixed-top-touch');
    //         nav.classList.add('is-fixed-top');
    //     } else {
    //         document.body.classList.remove('has-navbar-fixed-top-touch');
    //         nav.classList.remove('is-fixed-top');
    //     }
    // });
});
