import app from './base'
import magnificPopup from 'magnific-popup/dist/jquery.magnific-popup'

app.event.bind('gallery.init', function() {
    jQuery(this).magnificPopup({
        delegate: 'a', // the selector for gallery item
        type: 'image',
        gallery: {
            enabled:true
        }
    });

    jQuery(this).find('.gallery-slider').each(function() {
        let position = 0;

        const $imgLine = jQuery(this).find('ul');
        const $images = $imgLine.find('li');
        const moveStep = $images.first().width();
        const maxPosition = moveStep * $images.length;
        const $left = jQuery(this).parent().find('.button-left');
        const $right = jQuery(this).parent().find('.button-right');

        $left.click(function() {
            if( (maxPosition + position) > 3 * moveStep){
                position = position - moveStep;
                $imgLine.css({'left': position + 'px'});
            }

            if( (maxPosition + position) < 4*moveStep) $left.addClass('disabled');

            $right.removeClass('disabled');
        });
        $right.click(function() {
            if(position < 0){
                position = position + moveStep;
                $imgLine.css({'left': position + 'px'});
            }

            if( (position + moveStep) > 0) $right.addClass('disabled');

            $left.removeClass('disabled');
        });
    });
});
