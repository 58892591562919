import app from "./base";
import popup from "./popup";
import {parseCommaFormatted} from "./helper";

const product = {
    basket: {
        quantity: {
            element: function () {
                return jQuery("input[name='amount']")
            },
            raw: function () {
                return product.basket.quantity.element().val()
            },
            set: function (quantity) {
                product.basket.quantity.element().val(quantity);
            },
            get: function () {
                return parseCommaFormatted(product.basket.quantity.raw())
            },
            increase: function () {
                let b = product.basket.quantity.get();
                product.basket.quantity.set(b + 1)
            },
            decrease: function () {
                let b = product.basket.quantity.get() - 1;

                if (b < 1) {
                    b = 1
                }

                product.basket.quantity.set(b)
            },
        },
    }
};

app.event.bind('product.basket.add', function(params) {
    params.event.stopPropagation();
    params.event.preventDefault();
    const $form = $(this);
    jQuery.ajax({
        type: "POST",
        url: $form.attr('action'),
        data: $form.serialize() + "&layout=ajax",
        success: function (response, textStatus) {
            popup.html(response);
            app.event.trigger("basket.preview.update");
        }
    });
});

app.event.bind('product.basket.quantity.decrease', function() {
    product.basket.quantity.decrease();
});

app.event.bind('product.basket.quantity.increase', function() {
    product.basket.quantity.increase();
});

app.event.bind('product.basket.quantity.change', function() {
    const quantity = jQuery(this).val();

    if (quantity === "") {
        return;
    }

    product.basket.quantity.set(quantity);
});
