import $t from './translation'
import { APP_NAME } from './constants';
import sauna from './sauna'

// Import modules
import './slider'
import './navigation'
import './gallery'
import './form'
import './basket'
import './product'
import './user'
import './validator'
import './welcome'

// Import style, for webpack to build it
import '../styles/main.scss'

// Init the app and listen for on ready event.
window[APP_NAME] = (function () {
    sauna.initialize();
    return sauna;
})();

jQuery(document).ready( function(){
    sauna.event.trigger('document.ready');
});
