import app from './base';

const VALIDATION_ERROR_CLASS = "is-danger";

function b(a, b, d) {
    if (typeof b == "undefined") return alert("VALIDATION_FATAL_ERROR: field not found " + d.field), !1;
    var e = !1, f;
    f = RegExp("^([a-z]+)([[a-z0-9_-]+])$", "g").exec(a), f != null && f.length > 0 ? (f[2] = f[2].substr(1, f[2].length - 2), f = {
        rule: f[1],
        param: f[2]
    }) : f = !1, f && (a = f.rule, e = f.param);
    switch (a) {
        case"required":
        case"length":
        case"maxlength":
            return b.length > 0;
        case"checked":
            return a = jQuery(d.field, jQuery(g)), a.attr("type") == "radio" && (a = jQuery('input[name="' + a.attr("name") + '"]', jQuery(g))), a.is(":checked");
        case"regex":
            return RegExp(a, "g").test(b);
        case"match":
            return typeof h[e] != "undefined" ? b == c(h[e].field) : typeof jQuery(e, jQuery(g)) != "undefined" ? b == c(e) : b == e;
        default:
            if (e = a.replace("-", "_"), typeof i[e] != "undefined") return RegExp(i[e], "g").test(b);
            if (a.indexOf("^") === 0) return RegExp(a, "g").test(b);
            alert("VALIDATION_FATAL_ERROR: No validation type found " + a + " for field " + d.field)
    }
    return !1
}

function c(a) {
    return jQuery(a, jQuery(g)).is("select") ? jQuery(a, jQuery(g)).find("option:selected").val() : jQuery(a, jQuery(g)).val()
}

function d(b, c, d, e) {
    typeof d.highlight != "undefined" && d.highlight && jQuery(d.field, jQuery(g)).addClass(VALIDATION_ERROR_CLASS), typeof d.highlight == "string" && jQuery(d.highlight, jQuery(g)).addClass(VALIDATION_ERROR_CLASS), typeof d.error_box != "undefined" && (jQuery(d.error_box, jQuery(g)) || alert(d.error_box), typeof d.error == "string" ? jQuery(d.error_box, jQuery(g)).html(d.error) : jQuery(d.error_box, jQuery(g)).html(d.error.rule)), typeof d.error_callback != "undefined" && d.error_callback.call(this, b, d, c), app.event.trigger("validator.error", g, {
        field: b,
        rule: c,
        option: d,
        failed_rules: e
    })
}

function e(b, c, d) {
    typeof d.highlight != "undefined" && d.highlight && jQuery(d.field, jQuery(g)).removeClass(VALIDATION_ERROR_CLASS), typeof d.highlight == "string" && jQuery(d.highlight, jQuery(g)).removeClass(VALIDATION_ERROR_CLASS), typeof d.error_box != "undefined" && jQuery(d.error_box, jQuery(g)).html(""), typeof d.success_callback != "undefined" && d.success_callback.call(this, b, d, c), app.event.trigger("validator.success", g, {
        field: b,
        rule: c,
        option: d
    })
}

function f(a) {
    return a.replace(/[^a-zA-Z0-9_-]/gi, "_")
}

var g = null, h = {}, i = {
    username: "^[a-zA-Z0-9._-]{3,14}$",
    sex: "^[MN]{1}$",
    year: "^[1-2][0-9][0-9][0-9]$",
    month: "^(?:[0][1-9])$|^(?:[1][0-2])$",
    day: "^(?:[0][1-9])$|^(?:[12][0-9])$|^(?:[3][01])$",
    password: "^.{5,32}$",
    skype: "^[A-Za-z_.,-]{1,32}$",
    binary: "^[0-9]{1,32}$",
    icq: "^[d]{5,12}$",
    email: "^[a-zA-Z0-9-._+]{1,63}@[a-zA-Z0-9-._]{2,63}[.][a-z]{2,10}$",
    datetime: "^[0-9]{2,4}.[0-9]{2}.[0-9]{2,4}[ ][0-9]{2}[:][0-9]{2}$",
    date: "^[0-9]{2,4}.[0-9]{2}.[0-9]{2,4}$",
    eng_alphabet: "^[a-zA-Z_-]{1,}$",
    numeric: "^[0-9]{1,}$",
    phone: "^[+]?[0-9]{3,}$",
    tel: "^[+]?[0-9]{3,}$",
    percent: "^[0-9]{2}[%]{1}$",
    url: "^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?.)*[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$",
    url_strict: "^(ftp|http|https)://(w+:{0,1}w*@)?(S+)(:[0-9]+)?(/|/([w#!:.?+=&%@!-/]))?$",
    idd_phone: "^[+]?0[0|1]{1}[0-9]{3,}$"
};

let validator = {
    init: function ($form) {
        $form.unbind("submit.validator").bind("submit.validator", function (b) {
            return h = {}, b.stopPropagation(), b.preventDefault(), jQuery(this).find("input, select").each(function (b, c) {
                validator.validateField(c)
            }), validator.setForm(this).validate()
        }), $.fn.ajaxifyform && $form.parent().ajaxifyform()
    }, setForm: function (a) {
        return g = a, this
    }, setRule: function (a, b) {
        var c = {};
        return c[a] = b, jQuery.extend(h, c), this
    }, setRules: function (a) {
        return jQuery.extend(h, a), this
    }, validate: function () {
        var f = !0, i, j, n, o;
        for (o in h) {
            var q = !0, r = [];
            i = h[o];
            if (typeof jQuery(i.field, jQuery(g)) != "undefined") {
                j = c(i.field), n = typeof i.rules == "string" ? i.rules.split("|") : i.rules;
                for (var s in n) b(n[s], j, i) ? e(o, n[s], i) : (r.push(n[s]), d(o, n[s], i), q = f = !1);
                q ? e(o, "all", i) : d(o, "all", i, r)
            }
        }
        return app.event.trigger("validator.validate", g, {result: f}), f
    }, validateField: function (a) {
        var b = jQuery(a), c = b.attr("type"), d = [], e = b.attr("required");
        typeof e != "undefined" && e !== !1 && (c == "checkbox" || c == "radio" ? d.push("checked") : d.push("required"), c == "password" && d.push("password")), jQuery.inArray(c, "color,date,datetime,datetime-local,email,month,number,tel,time,url,week".split(",")) >= 0 && d.push(c), c = b.attr("pattern"), typeof c != "undefined" && c !== !1 && d.push(c), c = b.attr("min"), typeof c != "undefined" && c !== !1 && d.push("min[" + parseInt(c) + "]"), c = b.attr("max"), typeof c != "undefined" && c !== !1 && d.push("max[" + parseInt(c) + "]"), c = b.attr("maxlength"), typeof c != "undefined" && c !== !1 && d.push("maxlength[" + parseInt(c) + "]"), c = b.attr("data-val");
        if ((typeof c == "undefined" || c !== !1) && c !== 1) {
            var c = b.data(), g;
            for (g in c) g.indexOf("val") === 0 && (e = g.substr(3).toLowerCase(), e.length != 0 && (e == "rule" ? d.push(c[g]) : d.push(e + "[" + f(c[g]) + "]")))
        }
        d.length > 0 && (g = {}, g.rules = d, g.field = a, g.highlight = b.attr("data-highlight") ? b.attr("data-highlight") : !0, this.setRule(f(b.attr("name")), g))
    }, reset: function (a) {
        typeof a == "undefined" && (a = h);
        for (var b in a) e(a[b])
    }, print: function () {
        console.log(h)
    }
};

app.event.bind("validator.validate", function (b) {
    b.result === !0 && app.event.trigger("form.submit", this);
});

export default validator;
