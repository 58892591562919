import configuration from "./configuration";

export function intval(a, b) {
    var c;
    return c = typeof a, c === "boolean" ? a ? 1 : 0 : c === "string" ? (c = parseInt(a, b || 10), isNaN(c) || !isFinite(c) ? 0 : c) : c === "number" && isFinite(a) ? Math.floor(a) : 0
}

export function trim(value) {
    return value.replace(/^\s+|\s+$/g, '');
}

export function boolval(value) {
    if (typeof(value) === 'string'){
        value = trim(value).toLowerCase();
    }
    switch(value){
        case true:
        case "true":
        case 1:
        case "1":
        case "on":
        case "yes":
            return true;
        default:
            return false;
    }
}

export function isNumeric(a) {
    return (typeof a == "number" || typeof a == "string") && a !== "" && !isNaN(a)
}

export function parseCommaFormatted(a) {
    return a == null ? 0 : (a = a.split(" "), a = a.join(""), a = a.split(","), (new Number(a.join(""))).valueOf())
}

export function commaFormatted(a) {
    let decimal = parseCommaFormatted(a);
    if (isNaN(decimal)) return "";
    return number_format(decimal, 2, ".", ",");
}

export function sum(a, b) {
    return (new Number(a)).valueOf() + (new Number(b)).valueOf()
}

export function minus(a, b) {
    return (new Number(a)).valueOf() - (new Number(b)).valueOf()
}

export function roundNumber(num, scale) {
    if (typeof scale == "undefined" || scale < 0) scale = 2;
    if(!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if(+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}

export function countValuePercentage(a, b) {
    return roundNumber(100 - b * 100 / a, 2)
}

export function countPercentageValue(a, b) {
    var c = 0;
    return a > 0 && b > 0 && (c = a * b / 100), roundNumber(c, 2)
}

export function number_format(number, decimals, dec_point, thousands_sep) {
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export function formatAmount(s) {
    if (typeof (s) != 'string') {
        s = roundNumber(s, 2);
        s = s.toFixed(2);
        s = new String(s).valueOf();
    }
    if (String(s).indexOf(".") < 0) {
        return commaFormatted(s + ".00");
    }
    return commaFormatted(s);
}

export function getDomain() {
    var a = /\/index./i,
        b = /^[a-z]{2}\//i,
        c = window.location.pathname.substring(1),
        d = window.location.protocol + "//" + window.location.host + "/";
    if (c.length > 0 && a.test(c) === 1) {
        c = c.split("/");
        c.pop();
        d += c.join("/") + "/";
    } else {
        for (; c.length > 0 && b.test(c) == 0;) {
            c = c.split("/");
            d += c.shift() + "/";
            c = c.join("/");
        }
    }
    return d
}

export function getActionUrl(module, action, params) {
    var d = getDomain();
    return module != "" && action != "" && typeof module != "undefined" && typeof action != "undefined" && (d += "?action=" + module + "." + action), d + joinParams(params)
}

export function joinParams(a, b) {
    var c = "";
    return a ? (jQuery.each(a, function (a, d) {
        b && jQuery.inArray(a, b) > 0 || (c += "&" + a + "=" + encodeURIComponent(d))
    }), c) : c
}

export function serializeObject(a) {
    var b = {};
    return jQuery.each(jQuery(a).serializeArray(), function () {
        b[this.name] !== void 0 ? (b[this.name].push || (b[this.name] = [b[this.name]]), b[this.name].push(this.value || "")) : b[this.name] = this.value || ""
    }), b
}
