if(typeof window.String.prototype.trim !== 'function') {
    window.String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    }
}
if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}