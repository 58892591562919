import configuration from './configuration';

function $_replace(text, values){
    return text.replace(/\{([^:\}]+)(:[^\}]+)?\}/g, function(av,au,at){
        let aw = values[au],ar;
        if(at){
            ar=at.substr(1).split(/\s*\|\s*/);
            aw=(aw>=ar.length?ar[ar.length-1]:ar[aw]).replace(/##?/g,function(ax){
                return ax.length===2?"#":aw
            })
        }
        return aw
    })
}

export default function $t(key, replace, eventable){
    let translation = key;
    if(typeof language != 'undefined'){
        if(typeof language[key] != 'undefined'){
            translation = '';

            if(typeof(replace) != 'undefined' && replace !== '') {
                translation = $_replace(language[key], replace);
            } else {
                translation = language[key];
            }
        }
    }
    if(eventable !== false && configuration.get('authorized', '0') === '1' && ( configuration.get('eventable', '0') === '1' || eventable === '1')){
        translation = '<span id="' + key + '" class="cms-translation-item">' + translation + '</span>';
    }
    return translation;
}