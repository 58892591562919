import storage from "./storage";
import app from "./base";

const popupModal = (function(element) {
    const identifier = element.id;

    if (!identifier) {
        return;
    }

    const cookieIdentifier = `pop-up-${identifier}`;

    function openPopUp() {
        element.querySelectorAll('[data-pop-up-close]').forEach((e) => {
            e.addEventListener('click', function() {
                closePopUp(element);
            });
        });

        element.classList.add('opened');

        if (element.hasAttribute('data-pop-up-show-once')) {
            storage.cookie.set(cookieIdentifier, true);
        }

        if (!element.hasAttribute('data-pop-up-blocking')) {
            document.addEventListener('click', documentListener);
        }

        element.addEventListener('pop-up-close', function () {
            closePopUp(element);
        }, false)
    }

    function documentListener(e) {
        // click event inside the popup?
        const isContent = e.target.closest('.pop-up-content');
        // click event on the trigger?
        const isTrigger = e.target.closest('.pop-up-trigger');

        // If `isClosest` equals falsy & popup has the class `show`
        // then hide the popup
        if (!isContent && !isTrigger && element.classList.contains("opened")) {
            closePopUp(element);
        }
    }

    function closePopUp() {
        element.classList.remove('opened');

        storage.cookie.set(cookieIdentifier, true);
    }

    document.querySelectorAll(`[data-pop-up-trigger='${identifier}']`).forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            openPopUp(element);
        });
    });

    const shown = storage.cookie.get(cookieIdentifier);

    if(shown) {
        return;
    }

    const timeout = parseInt(element.getAttribute('data-pop-up-timeout'));

    if (timeout > 0) {
        setTimeout(function() {
            openPopUp(element);
        }, timeout * 1000);
    } else {
        openPopUp(element);
    }
});

app.event.bind('document.ready', function() {
    document.querySelectorAll('[data-pop-up]').forEach((e) => {
        popupModal(e);
    });
});
