import app from "./base";
import $t from './translation'
import magnificPopup from 'magnific-popup/dist/jquery.magnific-popup'

let popup = {
    loading: function(message) {
        if (typeof message === 'undefined') {
            message = $t('lbl.loading')
        }

        jQuery.magnificPopup.open({
            type: 'inline',
            mainClass: 'sauna-popup',
            modal: true,
            items: {
                src: '<div class="has-text-centered">' + message + '</div>'
            },
        });
    },
    html: function(html, className) {
        jQuery.magnificPopup.open({
            type: 'inline',
            mainClass: 'sauna-popup ' + (className || ''),
            tLoading: $t("lbl.loading"),
            closeOnContentClick: false,
            closeOnBgClick: false,
            showCloseBtn: true,
            enableEscapeKey: true,
            closeBtnInside: true,
            items: {
                src: html,
            },
            callbacks: {
                change: function () {
                    app.event.trigger('document.changed', this);
                }
            }
        });
    },
    url: function(url, className, callback) {
        jQuery.magnificPopup.open({
            type: 'ajax',
            mainClass: 'sauna-popup ' + (className || ''),
            tLoading: $t("lbl.loading"),
            closeOnContentClick: false,
            closeOnBgClick: false,
            showCloseBtn: true,
            enableEscapeKey: true,
            closeBtnInside: true,
            items: {
                src: url + (url.indexOf('?') >= 0 ? '&' : '?') + 'layout=ajax'
            },
            callbacks: {
                ajaxContentAdded: function() {
                    if (callback !== undefined) {
                        callback();
                    }
                },
                change: function () {
                    app.event.trigger('document.changed', this);
                }
            }
        });
    },
    confirm: function() {

    },
    close: function() {
        jQuery.magnificPopup.close();
    }
};

app.event.bind('popup.form', function(params) {
    const href = params.href || jQuery(this).attr('href');
    const className = params.class || '';
    if (params.event) {
        params.event.stopPropagation();
        params.event.preventDefault();
    }
    popup.close();
    popup.url(href, className);
});

app.event.bind('popup.close', function() {
    popup.close();
});

export default popup;